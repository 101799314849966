import {
  styled,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ModalCard from "../../Modals/ModalCard";

export const ItensCard = styled(ModalCard.Card)({
  maxHeight: "8.8rem",
});

export const StyledItensTable = styled(Table)({
  width: "100%",
  overflow: "auto",
});

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  "& tr th": {
    fontFamily: theme.font.Roboto,
    fontStyle: "normal",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "1rem",
    color: theme.colors.primary,
    padding: ".5rem",
  },
}));

export const StyledTableRow = styled(TableRow)({
  "& td, th": {
    border: "none",
  },
  "& .sku": {
    maxWidth: "3.1875rem",
  },
  "& .name": {
    maxWidth: "10rem",
  },
  "& .qtyComplaint": {
    maxWidth: "6.3125rem",
  },
});

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  "& tr td": {
    fontFamily: theme.font.Roboto,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1rem",
    color: theme.colors.primary,
    padding: ".5rem",
  },
}));
