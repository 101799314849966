import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  styled,
  TableBody,
  TextField,
} from "@material-ui/core";
import { Title } from "../../components/Title";
import { useState, CSSProperties, ChangeEvent, memo } from "react";
import { FiSearch } from "react-icons/fi";
import { v4 } from "uuid";

import NoData from "./NoData";
import Table from "./Table";
import TableCellBody from "./TableCellBody";
import TableCellHead from "./TableCellHead";
import TableContainer from "./TableContainer";
import TableHead, { TableRowHead } from "./TableHead";
import Pagination from "./TablePagination";
import TableRow from "./TableRow";

const TextFieldStyle = styled(TextField)(({ theme }) => ({
  maxWidth: "initial",
  [theme.breakpoints.up("sm")]: {
    width: "50%",
    maxWidth: "350px",
  },
}));

const FormFilterStyle = styled(FormControl)(({ theme }) => ({
  maxWidth: "initial",
  [theme.breakpoints.up("sm")]: {
    width: "45%",
    maxWidth: "300px",
  },
}));

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === `-${orderBy}`
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const TableCustom = (props) => {
  const {
    columns,
    checkboxSelection,
    data = [],
    onChange,
    params,
    status,
    refresh,
    title,
    count,
    handleSearch,
    rowStyle,
    hover,
    filterProps,
    rowOnClick,
    size,
    paginationServer = true,
  } = props;

  const [selected, setSelected] = useState([]);

  const isNoData = data.length === 0;

  const isSelected = (id) => !!selected.find((item) => item.id === id);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected((state) => [...state, ...data]);
      props?.onSelect?.(data);
      return;
    }
    props?.onSelect?.([]);
    setSelected([]);
  };

  const handleClick = (row) => {
    setSelected((state) => {
      const selectedItem = state.some((item) => item.id === row.id);

      if (selectedItem) {
        const filterItems = state.filter((item) => item.id !== row.id);
        props.onSelect?.(filterItems);
        return filterItems;
      }

      const items = [...state, row];
      props?.onSelect?.(items);
      return items;
    });
  };

  return (
    <Paper style={{ margin: "1rem 0 1rem", padding: "1rem 0" }}>
      {title && (
        <Title style={{ padding: "0 1.5rem 0", fontWeight: "bold" }}>
          {title}
        </Title>
      )}

      <Box
        style={{ padding: "0 0.8rem", width: "100%", gap: "1rem" }}
        display="flex"
        flexWrap="wrap"
      >
        {handleSearch && (
          <TextFieldStyle
            onChange={handleSearch}
            fullWidth
            variant="outlined"
            label="Pesquisar"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FiSearch />
                </InputAdornment>
              ),
            }}
          />
        )}

        {filterProps && (
          <FormFilterStyle variant="outlined" fullWidth>
            <InputLabel shrink>{filterProps.label}</InputLabel>
            <Select
              label={filterProps.label}
              value={params[filterProps.parameter] || ""}
              defaultValue=""
              displayEmpty
              onChange={(event) =>
                onChange({
                  [filterProps.parameter]: event.target.value || undefined,
                  page: 1,
                })
              }
            >
              <MenuItem value="">Todos</MenuItem>

              {filterProps.values.map((value) => {
                return (
                  <MenuItem key={value.id} value={value.id}>
                    {value.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormFilterStyle>
        )}
      </Box>

      <TableContainer style={{ minHeight: isNoData ? "21rem" : "initial" }}>
        <Table size={size || "medium"}>
          <TableHeader
            columns={columns}
            dataNumber={data.length}
            handleSelectAllClick={handleSelectAllClick}
            loading={status === "pending"}
            onChange={onChange}
            selectedNumber={selected.length}
            checkboxSelection={checkboxSelection}
            orderBy={params.orderBy}
            orderDirection={params.orderDirection}
          />

          <TableBody>
            {stableSort(
              data,
              getSorting(params?.orderDirection, params?.orderBy)
            )
              .slice(
                !paginationServer ? params.page * params.pageSize : 0,
                !paginationServer
                  ? params.page * params.pageSize + params.pageSize
                  : data.length
              )
              .map((item) => {
                const isItemSelected = isSelected(item.id);
                const id = v4();

                return (
                  <TableRow
                    key={id}
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                    onClick={() => rowOnClick && rowOnClick(item)}
                    hover={!!checkboxSelection || hover}
                    style={{
                      cursor: rowOnClick ? "pointer" : "initial",
                      ...(rowStyle ? rowStyle(item) : {}),
                    }}
                  >
                    {checkboxSelection && (
                      <TableCellBody
                        padding="checkbox"
                        style={{ paddingLeft: "1.5rem" }}
                      >
                        <Checkbox
                          onClick={() => handleClick(item)}
                          checked={isItemSelected}
                        />
                      </TableCellBody>
                    )}
                    {columns.map((column) => {
                      const idCell = v4();
                      const { cellStyle } = column;

                      if (column?.renderCell) {
                        return (
                          <TableCellBody
                            style={{ ...(cellStyle ? cellStyle(item) : {}) }}
                            key={idCell}
                            align={column?.align || "left"}
                          >
                            {column.renderCell({ data: item })}
                          </TableCellBody>
                        );
                      }

                      return (
                        <TableCellBody
                          key={idCell}
                          style={{ ...(cellStyle ? cellStyle(item) : {}) }}
                          align={column?.align || "left"}
                        >
                          {column?.formatter
                            ? column.formatter(item[column.field || ""] || "")
                            : item[column.field || ""] || "-"}
                        </TableCellBody>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {status !== "pending" && status !== "initial" && isNoData && (
          <NoData status={status} refresh={refresh} />
        )}
      </TableContainer>
      <Pagination
        page={params.page}
        count={count}
        onChange={(_, newPage) => onChange({ page: newPage })}
        rowsPerPage={params.pageSize}
        handleRowsPerPageChange={(newPageSize) =>
          onChange({ pageSize: newPageSize, page: 1 })
        }
      />
    </Paper>
  );
};

const TableHeader = memo((props) => {
  const {
    columns,
    checkboxSelection,
    loading,
    selectedNumber,
    dataNumber,
    handleSelectAllClick,
    orderDirection,
    orderBy,
    onChange,
  } = props;

  return (
    <TableHead>
      <TableRowHead>
        {checkboxSelection && (
          <TableCellHead padding="checkbox" type="checkbox" column="checkbox">
            <Checkbox
              indeterminate={selectedNumber > 0 && selectedNumber < dataNumber}
              checked={selectedNumber > 0 && selectedNumber === dataNumber}
              onChange={handleSelectAllClick}
            />
          </TableCellHead>
        )}

        {columns.map((column) => (
          <TableCellHead
            key={column?.field}
            style={column?.style}
            column={column.field || ""}
            align={column?.align}
            onChange={onChange}
            disabled={column?.disabledSort}
            paginationParams={{
              orderDirection,
              orderBy,
            }}
          >
            {column.label}
          </TableCellHead>
        ))}
      </TableRowHead>
      <TableRowHead>
        <TableCellBody
          colSpan={columns.length}
          style={{ padding: "0rem 0rem", minWidth: "50px" }}
        >
          {loading && <LinearProgress />}
        </TableCellBody>
      </TableRowHead>
    </TableHead>
  );
});

export default TableCustom;
