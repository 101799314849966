import { Box, makeStyles, styled } from "@material-ui/core";

export const useStyles = makeStyles({
  boxAlign: {
    marginTop: "0.5rem",
    display: "flex",
    justifyContent: "space-between",
  },
  titlePage: {
    color: "#353535",
    fontSize: "1.6rem",
    fontFamily: "Inter",
    fontWeight: 600,
    lineHeight: "1.7rem",
    letterSpacing: "0.01em",
    marginTop: "0.8rem",
  },
  configs: {
    border: "0.5px solid #757575",
    color: '#"#ffff"',
    fontSize: "1rem",
  },
  containner: {
    display: "flex",
    flexWrap: "wrap",
  },
  icon: {
    width: "1.2rem",
    height: "1.2rem",
    borderRadius: "6rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "0.7rem",
    "& > img": {
      width: "1.1rem",
    },
    "& > svg": {
      width: "1.5rem",
    },
  },
  pjOrderIcon: {
    background: "#00000",
    width: "25px",
    height: "25px",
    color: "green",
  },
});

export const ContainerCheckboxs = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridGap: "1rem",
  marginTop: "1rem",
  [theme.breakpoints.down(550)]: {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
}));
