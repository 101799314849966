import { TableCell } from "@material-ui/core";
import { useState } from "react";
import ModalCard from "../../Modals/ModalCard";
import {
  ItensCard,
  StyledItensTable,
  StyledTableBody,
  StyledTableHead,
  StyledTableRow,
} from "./style";

export const ItemsTableToApprove = ({ items, title }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <>
      <ItensCard>
        <ModalCard.Title>{title || "Itens da Quebra"}</ModalCard.Title>
        <StyledItensTable>
          <StyledTableHead>
            <StyledTableRow>
              <TableCell className="sku">SKU</TableCell>
              <TableCell className="name">Produto</TableCell>
              <TableCell className="reason">Motivo</TableCell>
              <TableCell className="qtyComplaint">Quantidade</TableCell>
              <TableCell className="value">Valor</TableCell>
              <TableCell className="image">Foto</TableCell>
            </StyledTableRow>
          </StyledTableHead>
          <StyledTableBody>
            {items?.map((item) => (
              <StyledTableRow key={item.itemId || item.id}>
                <TableCell className="sku">
                  {item.itemSku || item.sku}
                </TableCell>
                <TableCell className="name">
                  {item.itemName || item.name || item.product_name}
                </TableCell>
                <TableCell className="reason">{item?.reason}</TableCell>
                <TableCell className="qtyComplaint">
                  {(item.complaintQty || item.quantity_complaint).toFixed(3)}
                </TableCell>
                <TableCell className="value">
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(item.complaintValue)}
                </TableCell>
                {item.image && (
                  <TableCell className="image">
                    <img
                      src={item.image}
                      alt="Imagem do produto"
                      width="30"
                      height="30"
                      loading="lazy"
                      style={{ cursor: "pointer" }}
                      onClick={() => setSelectedImage(item.image)}
                    />
                  </TableCell>
                )}
              </StyledTableRow>
            ))}
          </StyledTableBody>
        </StyledItensTable>
      </ItensCard>

      {/* Modal para exibir imagem ampliada */}
      {selectedImage && (
        <ImageModal
          image={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </>
  );
};

const ImageModal = ({ image, onClose }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
      onClick={onClose}
    >
      <img
        src={image}
        alt="Imagem ampliada"
        style={{
          maxWidth: "90%",
          maxHeight: "90%",
          borderRadius: "8px",
          boxShadow: "0 4px 10px rgba(255, 255, 255, 0.2)",
        }}
      />
    </div>
  );
};
