import { ABACATE_BACKEND_URL } from "../settings";
import { api } from "../utils/Api/apiClient";

class PdfGeneratorService {
  constructor() {
    this.url = ABACATE_BACKEND_URL;
  }

  async pdfGenerator(route, deliveryDate, token, dynamicKey) {
    const url = `${this.url}/api/abacate/v1/logistic/pdf-single-route-generate/`;
    const body = { deliveryDate, route, generateByFront: true, dynamicKey };
    return await api.post(url, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  async getPdfLinks(deliveryDate, token) {
    const url =
      `${this.url}/api/abacate/v1/logistic/pdf_routes/?` +
      new URLSearchParams({ date: deliveryDate });

    const body = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return await fetch(url, body);
  }
}

const pdfGeneratorService = new PdfGeneratorService();
export default pdfGeneratorService;
