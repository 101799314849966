import { Box, MenuItem, Select, styled, Typography } from "@material-ui/core";
import { Pagination as MuiPagination, PaginationProps } from "@material-ui/lab";

import tableConfig from "../TableConfig";

const SelectStyle = styled(Select)({
  "& .MuiSelect-root": {
    paddingLeft: "0.5rem",
    fontSize: "0.9rem",
  },
  "&.MuiInput-underline:before": {
    display: "none",
  },
  "&.MuiInput-underline:after": {
    display: "none",
  },
});

const Pagination = (props) => {
  const { count, rowsPerPage, handleRowsPerPageChange, onChange, page } = props;

  return (
    <Box
      p="1rem"
      display="flex"
      justifyContent="center"
      style={{ gap: "1rem" }}
      width="max-content"
      m="0 auto"
    >
      <MuiPagination
        page={page}
        color="#ffff"
        onChange={onChange}
        count={Math.ceil(count / rowsPerPage)}
      />
      <Box display="flex" style={{ gap: "0.5rem" }} alignItems="center">
        <Typography style={{ fontSize: "0.9rem" }}>
          Linhas por página
        </Typography>

        <SelectStyle
          value={rowsPerPage}
          onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
        >
          {tableConfig.rowsPerPageOptions.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </SelectStyle>
      </Box>
    </Box>
  );
};

export default Pagination;
