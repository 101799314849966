import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDebaunce } from "../../utils/debaunce";

export const useTableParams = ({ service, paramsDefault }) => {
  const controller = new AbortController();
  const [status, setStatus] = useState("pending");
  const [pendingList, setpendingList] = useState([]);
  const [conferredList, setConferredList] = useState([]);
  const [inProcessList, setInProcessList] = useState([]);
  const location = useLocation();
  const navigate = useHistory();
  const [data, setData] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    search: new URLSearchParams(location.search).get("search") || undefined,
    orderDirection: "",
    ...paramsDefault,
  });
  const [count, setCount] = useState(0);
  const debaunceSearch = useDebaunce({ fn: handleSearch, delay: 500 });
  const debaunceChange = useDebaunce({ fn: onChange, delay: 200 });

  function handleSearch(e) {
    const search = e.target.value;
    setParams((state) => ({ ...state, page: 1, search }));
    updateUrl({ search });
  }

  function onChange(newParams) {
    setParams((state) => ({ ...state, ...newParams }));
  }

  const updateUrl = (newParams) => {
    const searchParams = new URLSearchParams(location.search);
    Object.keys(newParams).forEach((key) => {
      if (newParams[key] !== undefined) {
        searchParams.set(key, newParams[key]);
      } else {
        searchParams.delete(key);
      }
    });
    navigate({ pathname: location.pathname, search: searchParams.toString() });
  };

  const run = useCallback(async () => {
    try {
      setStatus("pending");
      const { results, totalCount, conferred, in_process, pending } =
        await service(
          {
            ...params,
            orderBy: params.orderDirection,
          },
          controller.signal
        );

      if (conferred && in_process && pending) {
        setpendingList(pending);
        setConferredList(conferred);
        setInProcessList(in_process);
      }

      setData(results);
      setCount(totalCount || 0);
      setStatus("success");
    } catch {
      if (controller.signal.aborted === false) {
        setStatus("error");
      }
    }
  }, [service, params]);

  useEffect(() => {
    run();
    return () => {
      controller.abort();
    };
  }, [params]);

  return {
    pendingList,
    conferredList,
    inProcessList,
    data,
    setData,
    params,
    onChange: debaunceChange,
    status,
    count,
    refresh: run,
    handleSearch: debaunceSearch,
  };
};
