import { Box } from "@material-ui/core";
import { Button } from "../../../components/Button";
import { ContainerPage } from "../../../components/ContainerPage";
import { usePopup } from "../../../hooks/usePopup";
import { useState } from "react";
import { useStyles } from "./styles";
import { IoAddSharp } from "react-icons/io5";
import { useTableParams } from "../../../hooks/useTableParams";
import TableCustom from "../../../shared/TableCustom";
import { ModalConfirmTwoFactors } from "./modalConfirm";
import authenticationService from "../../../services/authenticationService";
import React from "react";

export const TwoFactors = () => {
  const classes = useStyles();
  const { addPopup } = usePopup();
  const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [createData, setCreateData] = useState({
    qrCodeUri: "",
    secretKey: "",
  });
  const { count, data, params, refresh, onChange, status } = useTableParams({
    service: (params) => authenticationService.getTwofactorsUsers(),
  });

  const handleNewTwoFactor = async () => {
    try {
      setIsLoading(true);
      const response = await authenticationService.postTwofactorsUsers();
      setCreateData({
        qrCodeUri: response?.data?.qr_code_uri,
        secretKey: response?.data?.secret_key,
      });
      setModalCreateIsOpen(true);
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao criar autenticação.",
        description: error?.response?.data?.message || "Contate o time de tech",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmTwoFactor = async (secretKey) => {
    try {
      setIsLoading(true);
      await authenticationService.patchTwofactorsUsers(secretKey);
      addPopup({
        type: "success",
        title: "Autenticação criada com sucesso",
      });
      handleCloseModalCreate();
      refresh();
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao confirmar autenticação.",
        description: error?.response?.data?.message || "Contate o time de tech",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTwoFactor = async (twoFactorSecret) => {
    try {
      setIsLoading(true);
      const response = await authenticationService.deleteTwofactorsUsers(
        twoFactorSecret
      );
      addPopup({
        type: "success",
        title: "Autenticação excluída com sucesso",
        description: response?.message,
      });
      refresh();
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao excluir autenticação.",
        description: error?.response?.data?.message || "Contate o time de tech",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModalCreate = () => {
    setModalCreateIsOpen(false);
  };

  return (
    <>
      <ContainerPage>
        <Box className={classes.boxAlign}>
          <Button
            onClick={() => handleNewTwoFactor()}
            className={classes.configs}
            style={{
              textTransform: "none",
              marginTop: "2rem",
              marginLeft: "auto",
            }}
            variant="contained"
            disabled={isLoading}
          >
            <IoAddSharp size={"2rem"} style={{ marginRight: "0.6rem" }} />
            Criar autenticação pra mim
          </Button>
        </Box>

        <TableCustom
          columns={[
            {
              style: { fontSize: "1.2rem" },
              field: "username",
              label: "Usuário",
            },
            {
              style: { fontSize: "1.2rem" },
              field: "id",
              label: "",
              renderCell: (row) => {
                return (
                  <span>
                    {" "}
                    <Button
                      variant="contained"
                      style={{ background: "#f26357", color: "#ffff" }}
                      onClick={() =>
                        handleDeleteTwoFactor(row?.data?.two_factor_secret)
                      }
                      disabled={isLoading}
                    >
                      Excluir
                    </Button>
                  </span>
                );
              },
            },
          ]}
          data={data}
          params={params}
          onChange={onChange}
          refresh={refresh}
          count={count}
          status={status}
          rowStyle={() => ({
            fontSize: "1.1rem",
          })}
        />
      </ContainerPage>
      <ModalConfirmTwoFactors
        handleClose={handleCloseModalCreate}
        data={createData}
        handleConfirm={handleConfirmTwoFactor}
        open={modalCreateIsOpen}
        isLoading={isLoading}
      />
    </>
  );
};

export default TwoFactors;
