import {
  ButtonCancel,
  ButtonConfirm,
  Container,
  ContainerButtons,
  InfoCostumer,
  Loading,
  Modal,
  Title,
} from "./styles";
import { useEffect, useState } from "react";
import ReactQRCode from "react-qr-code";

export const ModalConfirmTwoFactors = ({
  handleClose,
  data,
  handleConfirm,
  open,
  isLoading,
}) => {
  return (
    <>
      <Modal open={open}>
        <Container>
          <Loading loading={isLoading} />
          <Title>Criar nova autenticação</Title>
          <InfoCostumer>
            <p>
              <strong>1°.</strong> Utilize um app de autenticação em seu celular
              pessoal.
            </p>
            <p>
              <strong>2°.</strong> Vá na opção de adicionar nova autenticação.
            </p>
            <p>
              <strong>3°.</strong> Selecione leitura de QrCode.
            </p>
            <p>
              <strong>4°.</strong> Escaneie o QRCode abaixo.
            </p>
            <p>
              <strong>5°.</strong> Verifique a nova autenticação com nome
              Carambola.
            </p>
            <p>
              <strong>6°.</strong> Clique em<strong>confirmar</strong> no botão
              abaixo e tá no jeito!
            </p>
          </InfoCostumer>
          <div style={{ textAlign: "center" }}>
            <ReactQRCode value={data.qrCodeUri} size={180} />{" "}
          </div>
          <ContainerButtons>
            <ButtonCancel onClick={handleClose} disabled={isLoading}>
              Cancelar
            </ButtonCancel>
            <ButtonConfirm
              onClick={() => handleConfirm(data?.secretKey)}
              disabled={isLoading}
            >
              Confirmar
            </ButtonConfirm>
          </ContainerButtons>
        </Container>
      </Modal>
    </>
  );
};
