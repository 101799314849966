import {
  alpha,
  styled,
  TableContainer as MuiTableContainer,
} from "@material-ui/core";

const TableContainer = styled(MuiTableContainer)(({ theme }) => ({
  boxShadow: theme.shadows[1],
  borderRadius: "0.5rem",
  "&::-webkit-scrollbar": {
    width: "4px",
    height: "7px",
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    background: alpha(theme.palette.grey[600], 0.3),
    borderRadius: "9px",
  },
}));

export default TableContainer;
