import { TableCell, styled } from "@material-ui/core";

const TableCellBody = styled(TableCell)({
  "&.MuiTableCell-root": {
    fontSize: "0.9rem",
    color: "#414141",
    fontWeight: 500,
  },
});

export default TableCellBody;
