import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import ModalCard from "../Modals/ModalCard";
import { CloseIconButton, EditIconButton } from "../StyledIconButton";
import { ItemsTable } from "../Table/ItemsTable";
import { Box, Grid } from "@material-ui/core";
import {
  CloseIconContent,
  ResolveAction,
  ResolveAlertInfo,
  ResolveContent,
  ResolveHead,
  ResolveHeadIcons,
  ResolveModalContainer,
} from "./styles";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Button } from "../Button";
import { StatusIcon } from "../Icons/StatusIcon";
import { ItemsTableToApprove } from "../Table/ItemsTableToApprove";
import React from "react";

const formattedItems = (items) => {
  return (items || []).map((item) => {
    return {
      itemId: item.id,
      itemSku: item.sku,
      itemName: item.product_name || item.product?.product_base.name,
      complaintQty: item.quantity_complaint,
      complaintValue: item.complaint_value,
      reason: item.reason.name,
      image: item.item_image,
      obs: item.observation,
    };
  });
};

export const BreakResolve = ({
  complaint,
  storeName,
  handleClose,
  handleOpenCoupon,
  handleOpenCreditAnnotation,
  handleOpenOtherNegotiations,
  handleOpenWithoutSolution,
  hasPermission,
  editPath,
}) => {
  const history = useHistory();

  const { complaintValue, items } = useMemo(() => {
    const complaintValue = complaint.items.reduce(
      (acc, { complaint_value }) => {
        return acc + complaint_value;
      },
      0
    );
    const items = formattedItems(complaint.items);

    return { complaintValue, items };
  }, [complaint]);

  const notReturned = complaint.items.some((item) => {
    const notItemReturned =
      !item?.quantity_returned || item?.quantity_returned === 0;
    const reasonAllowed =
      item?.reason?.name === "Não entregue" ||
      item?.reason?.name === "Quebra Prevista";

    return notItemReturned && !reasonAllowed;
  });

  const isPermitted = hasPermission("Can add action");
  return (
    <ResolveModalContainer>
      <CloseIconContent>
        <CloseIconButton onClick={handleClose} />
      </CloseIconContent>
      <ResolveContent>
        <ResolveHead>
          <span>{`Detalhe da Quebra - Pedido #${complaint?.orderId}`}</span>

          <ResolveHeadIcons>
            <EditIconButton
              onClick={() =>
                history.push(
                  `${editPath}${complaint?.orderId}/break/${complaint?.id}/edit`
                )
              }
            />
          </ResolveHeadIcons>
        </ResolveHead>

        {notReturned && (
          <Grid className="cardAlert">
            <Grid className="notReturnedCard">
              <div className="major">
                <StatusIcon statusId={"10"} />
                <div>
                  <p>Existem itens não devolvidos nessa quebra</p>
                </div>
              </div>
            </Grid>
          </Grid>
        )}

        <ModalCard.Card>
          <ModalCard.Title>Dados do Cliente</ModalCard.Title>
          <ModalCard.Text>
            Nome:
            <ModalCard.TextValue>{complaint?.clientName}</ModalCard.TextValue>
          </ModalCard.Text>
          <ModalCard.Text>
            Contato:
            <ModalCard.TextValue>{complaint?.clientPhone}</ModalCard.TextValue>
          </ModalCard.Text>
          <ModalCard.Text>
            Email:
            <ModalCard.TextValue>{complaint?.clientEmail}</ModalCard.TextValue>
          </ModalCard.Text>
        </ModalCard.Card>

        <ItemsTable items={items} />

        <ModalCard.Text>
          Criado por:
          <ModalCard.TextValue>
            {complaint.createdBy || "Não informado"}
          </ModalCard.TextValue>
        </ModalCard.Text>

        <ResolveAlertInfo>
          <h3>
            <ErrorOutlineIcon /> Evitar tratativas de reposição e reembolso.
          </h3>
        </ResolveAlertInfo>
        <ResolveAction>
          {!storeName?.includes("acerola") && (
            <Box className="principal-action">
              <Button
                onClick={() => handleOpenCreditAnnotation(complaintValue)}
              >
                Carteira
              </Button>
            </Box>
          )}
          <Box className="action">
            <Button
              onClick={() => handleOpenWithoutSolution(complaintValue)}
              style={{
                visibility: isPermitted ? "visible" : "hidden",
              }}
            >
              Sem tratativa
            </Button>
            <Button onClick={() => handleOpenCoupon(complaintValue)}>
              Cupom
            </Button>
            <Button onClick={() => handleOpenOtherNegotiations(complaintValue)}>
              Outras tratativas
            </Button>
          </Box>
        </ResolveAction>
      </ResolveContent>
    </ResolveModalContainer>
  );
};

export const ToApproveModal = ({
  complaint,
  storeName,
  handleClose,
  handleOpenCreditAnnotation,
  handleConfirm,
}) => {
  const { complaintValue, items } = useMemo(() => {
    const complaintValue = complaint.items.reduce(
      (acc, { complaint_value }) => {
        return acc + complaint_value;
      },
      0
    );
    const items = formattedItems(complaint.items);

    return { complaintValue, items };
  }, [complaint]);

  return (
    <ResolveModalContainer style={{ width: "40rem" }}>
      <CloseIconContent>
        <CloseIconButton onClick={handleClose} />
      </CloseIconContent>
      <ResolveContent>
        <ResolveHead>
          <span>{`Detalhe da Quebra - Pedido #${complaint?.orderId}`}</span>
        </ResolveHead>

        <ModalCard.Card>
          <ModalCard.Title>Dados do Cliente</ModalCard.Title>
          <ModalCard.Text>
            Nome:
            <ModalCard.TextValue>{complaint?.clientName}</ModalCard.TextValue>
          </ModalCard.Text>
          <ModalCard.Text>
            Contato:
            <ModalCard.TextValue>{complaint?.clientPhone}</ModalCard.TextValue>
          </ModalCard.Text>
          <ModalCard.Text>
            Email:
            <ModalCard.TextValue>{complaint?.clientEmail}</ModalCard.TextValue>
          </ModalCard.Text>
          <ModalCard.Text>
            Documento:
            <ModalCard.TextValue>
              {complaint?.order?.client?.cpf_cnpj}
            </ModalCard.TextValue>
          </ModalCard.Text>
        </ModalCard.Card>

        <ModalCard.Card>
          <ModalCard.Text>
            Valor:
            <ModalCard.TextValue>
              {complaint?.complaintValue}
            </ModalCard.TextValue>
          </ModalCard.Text>
          <ModalCard.Text>
            Solução:
            <ModalCard.TextValue>Crédito na carteira</ModalCard.TextValue>
          </ModalCard.Text>
        </ModalCard.Card>

        <ItemsTableToApprove items={items} />

        <ResolveAction>
          {!storeName?.includes("acerola") && (
            <Box className="principal-action">
              <Button
                onClick={() => handleOpenCreditAnnotation(complaintValue)}
              >
                Aprovar
              </Button>
            </Box>
          )}
          <Box className="decline-action">
            <Button onClick={() => handleConfirm("deleteBreak")}>Negar</Button>
          </Box>
        </ResolveAction>
      </ResolveContent>
    </ResolveModalContainer>
  );
};
