import { api } from "../utils/Api/apiClient";

const login = async (username, password) => {
  const url = "/api/auth-internal/v2/token";
  return await api.post(url, {
    username,
    password,
  });
};

const refresh = async (refresh) => {
  const url = "/api/auth-internal/v2/token/refresh";
  return await api.post(url, { refresh });
};

const getTwofactorsUsers = async () => {
  const response = await api.get(`/api/auth-internal/v2/two-factors`);
  return response["data"];
};

const postTwofactorsUsers = async () => {
  return await api.post(`/api/auth-internal/v2/two-factors`);
};

const patchTwofactorsUsers = async (secretKey) => {
  return await api.patch(`/api/auth-internal/v2/two-factors/${secretKey}`);
};

const deleteTwofactorsUsers = async (secretKey) => {
  return await api.delete(`/api/auth-internal/v2/two-factors/${secretKey}`);
};

const authenticationService = {
  login,
  refresh,
  getTwofactorsUsers,
  postTwofactorsUsers,
  patchTwofactorsUsers,
  deleteTwofactorsUsers,
};

export default authenticationService;
